import React, { useState, useContext, createContext } from "react";
import "./App.css";
import CalciteThemeProvider from "calcite-react/CalciteThemeProvider";
import styled from "styled-components";

//Component imports
import { WebMapView } from "./WebMapView";
import MainNav from "./MainNav";
import Footer from "./Footer";
import SidePanel from "./SidePanel";
import Picterra from "./Picterra";
import { ImageProvider } from "./ImageContext";
import { MapProvider } from "./MapContext";




const Container = styled.div`
  display: grid;
  width: auto;
  height: 100vh;
  grid-template-columns: 2fr 4fr;
  grid-template-rows: 60px 1fr 70px;
  grid-gap: 1rem;
`;

const Header = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  align-items: center;
`;

const LeftPanel = styled.div`
  background-color: white;

  min-width: 160px;
  grid-gap: 1px;
  overflow: auto;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(159px, 1fr));
`;
const RightPanel = styled.div`
  grid-row: 2 / 3;
  grid-column: 2 / 3;
`;

const FooterPanel = styled.div`
  color: #4c4c4c;
  grid-row: 3 / 3;
  grid-column: 1 / 3;
  background-color: gray;

`;

function App() {
  //const [selectedImage, SetSelectedImage] = useState();

  return (
    <div id="App">
      <CalciteThemeProvider>
        <Container>
          <ImageProvider>
          <MapProvider>
            <Header>
              <MainNav />
            </Header>
            <LeftPanel>
              <SidePanel />
            </LeftPanel>
            <RightPanel>
              <WebMapView />
            </RightPanel>
            <FooterPanel>
              <Picterra/>
            </FooterPanel>
          </MapProvider>
          </ImageProvider>
        </Container>
      </CalciteThemeProvider>
    </div>
  );
}

export default App;
