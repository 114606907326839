import React, { useEffect, useRef, useContext } from "react";
import { setDefaultOptions, loadModules } from "esri-loader";
import { ImageContext, ActiveImageContext,AnalysisModeContext,ProcessingResultsContext } from "./ImageContext";
import { MapContext,MapAOIContext } from "./MapContext";
import { geojsonToArcGIS } from "@terraformer/arcgis"
import { arcgisToGeoJSON } from "@terraformer/arcgis"

//setDefaultOptions({ css: true });

//import { loadCss } from 'esri-loader';

// by default loadCss() loads styles for the latest 4.x version
//loadCss();

export const WebMapView = () => {
  const mapRef = useRef();
  const mapView = useRef();
  const mapx = useRef();
  const mosaicx = useRef();
  const sketchExpandx = useRef();
  const sketchx = useRef();
  const bookmarksx = useRef();

  const imageserviceURL =
    "https://global.imsma.org/server/rest/services/Imagery/imsma_test_uav_imagery_Ref/ImageServer";
  const featureServiceURL =
    "https://global.imsma.org/server/rest/services/Imagery/UAV_Footprints/FeatureServer/0";
  const analysisServiceURL = [""];
  const analysisGeoJsonUrl = [""];

  const [images, setImages] = useContext(ImageContext);
  const [activeCard, setActiveCard] = useContext(ActiveImageContext);
  const [getMapData, setMapData] = useContext(MapContext);
  const [getMapAOI, setMapAOI] = useContext(MapAOIContext);
  const [AnalysisMode, setAnalysisMode] = useContext(AnalysisModeContext);
  const [processingResults, setProcessingResults] = useContext(ProcessingResultsContext);

  const ZoomtoImage = (image) => {
    console.log("zooming to imag", image);
  };

  function arcgisToJson(feature) {
    // parse ArcGIS JSON, convert it to GeoJSON
    const geojson = arcgisToGeoJSON(feature);

    return geojson;
  }

  function jsonToArcgis(feature) {
    // parse ArcGIS JSON, convert it to GeoJSON
    const geojson = geojsonToArcGIS(feature);

    return geojson;
  }

  useEffect(() => {
    const updateStsate = (x) => {
      setImages(x);
    };
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/widgets/Home",
        "esri/widgets/Bookmarks",
        "esri/widgets/Bookmarks/BookmarksViewModel",
        "esri/widgets/Expand",
        "esri/layers/ImageryLayer",
        "esri/layers/FeatureLayer",
        "esri/layers/support/MosaicRule",
        "esri/core/watchUtils",
        "esri/tasks/support/Query",
        "esri/widgets/Sketch",
        "esri/layers/GraphicsLayer",
        "esri/geometry/geometryEngine",
        "esri/geometry/support/webMercatorUtils",
                "esri/layers/GeoJSONLayer"

      ],
      { css: true }
    ).then(
      ([
        ArcGISMap,
        MapView,
        Home,
        Bookmarks,
        BookmarksViewModel,
        Expand,
        ImageryLayer,
        FeatureLayer,
        MosaicRule,
        watchUtils,
        Query,
        Sketch,
        GraphicsLayer,
        geometryEngine,
        webMercatorUtils,GeoJSONLayer
      ]) => {
        const map = new ArcGISMap({
          basemap: "topo-vector",
        });

        // load the map view at the ref's DOM node
        const view = new MapView({
          container: mapRef.current,
          map: map,
          center: [40.4, 0.64],
          zoom: 6,
        });

        mapx.current = map;
        mapView.current = view;

        const homeButton = new Home({
          view: view,
        });
        view.ui.add(homeButton, {
          position: "top-right",
        });


     

        const graphicsLayer = new GraphicsLayer();
        const graphicsLayer2 = new GraphicsLayer();

        // create a new sketch widget
        const sketch = new Sketch({
          view,
          layer: graphicsLayer2,
        });
        sketch.layout = "vertical";
        sketch.availableCreateTools = ["rectangle"];
        sketch.creationMode = "single";

        sketchx.current = sketch;

        const sketchExpand = new Expand({
          view: mapView.current,
          content: sketchx.current
        });
        sketchExpandx.current = sketchExpand;

      

        // listen to create event, only respond when event's state changes to complete
        sketch.on("create", function (event) {

          if (event.state === "start") {}
          graphicsLayer2.removeAll();

          if (event.state === "complete") {

            graphicsLayer2.add(event.graphic);

            let size = geometryEngine.geodesicArea(event.graphic.geometry, "square-kilometers");

            if (size < 20 ){
      console.log(`YOU ARE ${size} Km2`)
      
      
      setMapAOI(arcgisToJson(webMercatorUtils.webMercatorToGeographic(event.graphic.geometry)));
     // setMapAOI(event.graphic.geometry);

            }
            else{
console.log(`YOU ARE TOOO UUUGE`)

            }

            // remove the graphic from the layer associated with the Sketch widget
            // instead use the polygon that user created to query features that
            // intersect it.
        //       graphicsLayer.remove(event.graphic);
          }
        });
        

        //sketchViewModel.on("update", onGraphicUpdate);




        const bookmarks = new Bookmarks({
          view: view,
          editingEnabled: true,
          bookmarks: [],
          // whenever a new bookmark is created, a 100x100 px
          // screenshot of the view will be taken and the extent
          // of the view will not be set as the extent of the new bookmark
          bookmarkCreationOptions: {
            takeScreenshot: true,
            captureExtent: true,
            screenshotSettings: {
              width: 100,
              height: 100
            }
          }
       });
       bookmarksx.current = bookmarks;
       const bookmarksExpand = new Expand({
        view: mapView.current,
        content: bookmarksx.current
      });
      mapView.current.ui.add(bookmarksExpand, "top-right");   
      

      
      // collapses the associated Expand instance
      // when the user selects a bookmark
      bookmarks.on("select-bookmark", function(event){
        bookmarksExpand.expanded = false;
      });








        // MAP SHIZ
        const { whenFalse, whenFalseOnce } = watchUtils; //watchutls

        let mr = new MosaicRule();
        mr.method = "lock-raster";
        mr.lockRasterIds = [12345689];
        mosaicx.current = mr;
        /*imageserviceURL.forEach(function (layer) {
        map.add(layer);
      });*/

        let UavImageLayer = new ImageryLayer({ url: imageserviceURL });
        let UavFeatureLayer = new FeatureLayer({ url: featureServiceURL });
        map.add(UavFeatureLayer);
        UavFeatureLayer.outFields = ["*"];

        UavFeatureLayer.load().then(function () {
          //should be whenLayerView? instead of .load??
          view.extent = UavFeatureLayer.fullExtent;
        });

        UavImageLayer.mosaicRule = mr;
        map.add(UavImageLayer);
        map.add(graphicsLayer);
        map.add(graphicsLayer2);

        watchUtils.whenTrue(view, "stationary", function () {
          // misc listenner to get values
          if (view.extent) {
            var info =
              "<br> <span> the view extent changed: </span>" +
              "<br> xmin:" +
              view.extent.xmin.toFixed(2) +
              " xmax: " +
              view.extent.xmax.toFixed(2) +
              "<br> ymin:" +
              view.extent.ymin.toFixed(2) +
              " ymax: " +
              view.extent.ymax.toFixed(2) +
              "zoom - " +
              view.zoom +
              "scale - " +
              view.scale;
            //console.log(info);
           // console.log(view.scale);

            const query = new Query();
            query.geometry = view.extent;
            query.spatialRelationship = "envelope-intersects"; // the point location of the pointer
            // query.outSpatialReference = { wkid: 102100 };
            query.returnGeometry = true;
            query.outFields = ["*"];

            UavFeatureLayer.queryFeatures(query).then(function (results) {
              //  console.log(results.features); // prints the array of features to the console

              ///  results.features.map((feature) => {
              //   console.log(feature.attributes);
              //  });

              //react info collection

              updateStsate(results.features);
            });
          }

          // Listen for View click events
          view.on("click", function (event) {
            console.log("cluck");

            view.hitTest(event).then(function (response) {
              console.log("cluck hitest");
              // Retrieve the first symbol
              //var graphic = response.results[0].graphic;

              try {
                if (response.results.length) {
                  let graphic = response.results.filter(function (result) {
                    // check if the graphic belongs to the layer of interest
                    return result.graphic.layer === UavFeatureLayer;
                  })[0].graphic;
                  if (graphic) {
                    console.log("2d", graphic.attributes.misc1);
                    setActiveCard(graphic.attributes.misc1);
                    console.log("2d", graphic.attributes);
                  }
                }
              } catch (error) {
                console.error(error);
              }
            });
          });
        }); //END MAP SHIZ

        return () => {
          if (view) {
            // destroy the map view
            view.container = null;
          }
        };
      }
    );
  }, []);

  useEffect(() => {
    console.log(activeCard, "bob");
    if (mapView.current) {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/Query",
        "esri/Graphic",
        "esri/symbols/SimpleLineSymbol",
        "esri/Color",
        "esri/geometry/Polygon",
        "esri/layers/GeoJSONLayer",
        "esri/widgets/Expand",

      ]).then(
        ([QueryTask, Query, Graphic, SimpleLineSymbol, Color,Polygon,GeoJSONLayer,Expand]) => {

        if (AnalysisMode){

       
          mapView.current.ui.add(sketchExpandx.current, "top-right");

        //  mapView.current.ui.add(sketchx.current, "top-right");

        }

        /* */
          var queryZoomTask = new QueryTask({
            url: featureServiceURL,
          });
          var query = new Query();
          query.returnGeometry = true;
          query.where = "misc1 = '" + activeCard + "'";

          queryZoomTask.execute(query).then(function (result) {
            console.log("output of search", result.features[0].geometry.extent);

            var firstLayer = result.features[0];
            console.log(result.features[0]);
            var geometry = firstLayer.geometry;
            if (geometry.type === "polygon") {
              var symbol = { type: "simple-line", color: [255, 20, 147, 1] };

              var graphic = new Graphic(geometry, symbol);
              mapView.current.graphics.removeAll();
              mapView.current.graphics.add(graphic);
            }

            mapView.current.extent = result.features[0].geometry.extent.expand(
              1.5
            );

            mosaicx.current.lockRasterIds = [activeCard];
            console.log(mosaicx.current.lockRasterIds);
          });

         

     
        } // end alt map shi
      );
    }
    document.title = activeCard;
  }, [activeCard,AnalysisMode,processingResults]);

  useEffect(() => {
    if (mapView.current) {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/Query",
        "esri/Graphic",
        "esri/symbols/SimpleLineSymbol",
        "esri/Color",
        "esri/geometry/Polygon",
        "esri/layers/GeoJSONLayer",
        "esri/widgets/Expand",
        "esri/geometry/support/webMercatorUtils",


      ]).then(
        ([QueryTask, Query, Graphic, SimpleLineSymbol, Color,Polygon,GeoJSONLayer,Expand,webMercatorUtils]) => {

    if (getMapData) {
      console.log("some kind of activity here:");
     let  y = geojsonToArcGIS(getMapData);
    let x = webMercatorUtils.geographicToWebMercator(y)
      
if (true){ //x.length
  console.log("symbolising result polygons")
  let symbol = { type: "simple-line", color: [255, 0, 0, 1] };

  //const xc  =  x.map((feature) => (
  //  console.log(x)
    let polygon = new Polygon(x)
    const graphic = new Graphic({
      geometry: polygon,
      symbol: symbol //{ type: "simple-fill" }
    });
  //  view.graphics.add(graphic);
  console.log("p",polygon)
  console.log("g",graphic.geometry.extent)
    mapView.current.graphics.add(graphic)
    console.log("map graphic",graphic)
    if(graphic.geometry.extent){
      mapView.current.extent = graphic.geometry.extent.expand(2); 
    }

    
    
 //   if(ProcessingResults)
 //   {
  //   let geoJSONLayerx = new GeoJSONLayer({
  //    url: processingResults,
  //    copyright: "GICHD TEST",
 //  });
//    mapx.current.add(geoJSONLayerx);  // adds the layer to the map
//   mapView.current.extent = geoJSONLayerx.fullExtent;

 //   }
  

    
    //  ))


    //var geometry = x.geometry;
    //if (geometry.type === "polygon") {

 //     var graphic = new Graphic(geometry, symbol);
   //   mapView.current.graphics.add(graphic);
}

    }




        }) }



    },[getMapData]);
  

  return <div className="webmap" ref={mapRef} />;
};
