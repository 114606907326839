import React from "react";
import styled from "styled-components";


// Component imports
import TopNav from "calcite-react/TopNav";
import TopNavBrand from "calcite-react/TopNav/TopNavBrand";
import TopNavTitle from "calcite-react/TopNav/TopNavTitle";
import TopNavList from "calcite-react/TopNav/TopNavList";
import TopNavLink from "calcite-react/TopNav/TopNavLink";


const Logo = styled(TopNavBrand)`
  justify-content: center;
  & img {
    height: 55px;
  }
`;

const Nav = styled(TopNav)`
  && {
    z-index: 5;
    background-color: gray;
    color:white;
  }
`;



const NavList = styled(TopNavList)`
  text-align: right;
`;


const TopNavL = styled(TopNavLink)`
  text-align: right;
  color:white;
  &:hover {
    color:white
  }
`;

const TopNavLhidden = styled(TopNavLink)`
  text-align: right;
  display: none;
  color:white;
  &:hover {
    color:white
  }
`;
const TopNavText = styled(TopNavTitle)`
  text-align: left;
  color:white;
  &:hover {
    color:white
  }
`;

function MainNav() {
  return (
    <div>
      <Nav>
        <Logo
          href="#"
          src="https://d3n8a8pro7vhmx.cloudfront.net/themes/5a1dad12c4b9f537da000000/attachments/original/1514958036/logo-bug.png?1514958036"
        />
        <TopNavText href="#">Chad Imagery Viewer</TopNavText>
        <NavList>
          <TopNavLhidden href="#">
            Sign into Portal
          </TopNavLhidden>
          <TopNavL href="mailto:w.pedersen@gichd.org">Contact </TopNavL>
        </NavList>
        <Logo
          href="https://www.gichd.org/"
          src="https://global.imsma.org/logos/GICHD_Logo_2013_rvb_negatif.png" 
        />



      </Nav>
    </div>
  );
}

export default MainNav;
