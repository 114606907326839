import React, { useState, createContext } from "react";

export const MapContext = createContext(); 
export const MapAOIContext = createContext(); 

export const MapProvider = (props) => {
  const [getMapData, setMapData] = useState();
  const [getMapAOI, setMapAOI] = useState();

  return <MapContext.Provider value={[getMapData,setMapData]} >
     <MapAOIContext.Provider value={[getMapAOI,setMapAOI]} >
      {props.children}
      </MapAOIContext.Provider>
      </MapContext.Provider>
};
