import React, { useContext, useState } from "react";
import ImageCard from "./ImageCard";
import { ImageContext,ActiveImageContext } from "./ImageContext";

function SidePanel() {
  const [images, setImages] = useContext(ImageContext);
  
  const items = images || [];

  return (
<>

  {
  items.map((feature) => (
    

    <ImageCard
      feature={feature.attributes}
      //activeCard={activeCard}
     // setActiveCard={setActiveCard}
      key={feature.attributes.objectid}

    />
    
  ))


  }

  </>

  )
  
}

export default SidePanel;
