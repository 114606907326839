import React, { useState, createContext } from "react";

export const ImageContext = createContext(); 
export const ActiveImageContext = createContext(); 
export const AnalysisModeContext = createContext(); 
export const ProcessingResultsContext = createContext(); 

export const ImageProvider = (props) => {
  const [images, setImages] = useState();
  const [activeCard, setActiveCard] = useState();
  const [AnalysisMode, setAnalysisMode] = useState();
  const [processingResults, setProcessingResults] = useState(null);

  return <ImageContext.Provider value={[images,setImages]} >
     <ActiveImageContext.Provider value={[activeCard,setActiveCard]} >
     <AnalysisModeContext.Provider value={[AnalysisMode,setAnalysisMode]} >
     <ProcessingResultsContext.Provider value={[processingResults,setProcessingResults]} >
      {props.children}
      </ProcessingResultsContext.Provider>
      </AnalysisModeContext.Provider>
      </ActiveImageContext.Provider>
      </ImageContext.Provider>
};
