import React, { useContext } from "react";
import styled from "styled-components";
import ZoomToObjectIcon from "calcite-ui-icons-react/ZoomToObjectIcon";
import ImageIcon from "calcite-ui-icons-react/ImageIcon";
import DownloadIcon from "calcite-ui-icons-react/DownloadIcon";
import InformationIcon from "calcite-ui-icons-react/InformationIcon";
import SuitabilityAnalysisOutputIcon from "calcite-ui-icons-react/SuitabilityAnalysisOutputIcon";
import {ActiveImageContext} from './ImageContext'
import {AnalysisModeContext} from './ImageContext'
import Axios from "axios";

const Card = styled.div`
  padding: 2px;
  display: grid !important;
  grid-template-columns: 1fr;
  border-style: solid;
  border-color: lightgray;
  border-width: 2px;
  max-height: 60vh;
  background: white;
`;

const CardHeaderTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
  background: lightgray;
`;
const CardContents = styled.div`
  align-self: end;
`;

const CardFooter = styled.div`
  align-self: end;
  background: lightgray;
`;

const ActiveCardDiv = styled(Card)`
  border-color: purple;
  border-style: solid;
  border-width: 2px;
`;

const UavLinksBar = styled.div`
  display: grid;
  place-items: center center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  font-size: 1const 5em;
`;

const UavLinksBarDiv = styled.div`
  overflow: hidden;
`;

const UavLink = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const CardImage = styled.img`
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 175px;
`;

function ImageCard(props) {
  const [activeCard, setActiveCard] = useContext(ActiveImageContext);
  const [AnalysisMode, setAnalysisMode] = useContext(AnalysisModeContext);

  function setCardActive(e) {
    setActiveCard(e.currentTarget.id);
    console.log("Marlene, Give me some peace!... :)", e.currentTarget.id);
    e.currentTarget.scrollIntoView({
      behavior: "smooth",
    });
  }
  function analyseClick(e) {
  //  e.stopPropagation();

  //  /document.getElementById("gobutton").style  
   document.getElementById('gobutton').setAttribute('style', 'border-color:red; border-width:2px;');

   // className={`box ${isBoxVisible ? "" : "hidden"}`

    console.log("The analyse link was clicked.");
    setAnalysisMode(true)
  }
  function downloadClick(e) {
    e.stopPropagation();
    console.log("The download link was clicked.");


   // downloadRaster(c.graphic.attributes.misc1, activePoint.geometry);
 // function downloadRaster(id, polygon) {
  Axios.get(`https://global.imsma.org/server/rest/services/Imagery/imsma_test_uav_imagery_Ref/ImageServer/download?rasterIds=${activeCard}&f=json` ).then((results) => {
  //Axios.get(`https://cors-anywhere.herokuapp.com/https://global.imsma.org/server/rest/services/Imagery/imsma_test_uav_imagery_Ref/ImageServer/ExportImage/?mosaicRule={"mosaicMethod":"esriMosaicLockRaster","lockRasterIds":[${activeCard}]}&f=image` ).then((results) => {
//
//results.data.rasterFiles[0].id.split(/[.]+/).pop();



var arr = results.data.rasterFiles[0].id.split("/");      // Split the string using dot as separator
var lastVal = arr.pop();  

console.log(results.data.rasterFiles[0].id)
console.log(lastVal)


const link = document.createElement('a');
  link.href = `https://global.imsma.org/Imagery/${results.data.rasterFiles[0].id}`;
  link.download = lastVal;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);




  })
  }
  function addPortalClick(e) {
    e.stopPropagation();

    console.log("The preview in portal link was clicked.");
    let url = "https://global.imsma.org/portal/home/webmap/viewer.html?webmap=8ba5798cc40349eaa5e5e187f0855f9b"  //de0ea421dd6245df972f3c83e2c85570
    window.open(url, '_blank');

    //https://global.imsma.org/portal/apps/webappviewer/index.html?id=c73a69bb64234ae58c60d0277dafbd75&query=UAV_Footprints_3173%2Cname%2CBombs_Ortho
    //https://global.imsma.org/portal/apps/webappviewer/index.html?id=c73a69bb64234ae58c60d0277dafbd75&query=imsma_test_uav_imagery_Ref_577%2Cname%2CBombs_Ortho
  }
  function detailsClick(e) {
    e.stopPropagation();

    console.log("The details link was clicked.");
  }

  let CardClass = Card;
  if (props.feature.misc1 == activeCard) {
    CardClass = ActiveCardDiv;
  }

  return (
    <CardClass id={props.feature.misc1} onClick={setCardActive}>
      <CardHeaderTitle>{props.feature.name}</CardHeaderTitle>
      <CardImage src={props.feature.thumnail}></CardImage>
      <CardContents>
        <div className="desc">Publisher: {props.feature.organisation}</div>
        <div className="desc">Country: Tchad </div>
        <div className="desc">Org: {props.feature.organisation}</div>
        <div className="desc">Type: {props.feature.uav_type}</div>
        <div className="oid">ObjectId: {props.feature.objectid}</div>
        <div className="misc1">AltId: {props.feature.misc1}</div>
      </CardContents>
      <CardFooter>
        <UavLinksBar>
          <UavLink title="Download" onClick={downloadClick}>
            <DownloadIcon />
          </UavLink>
         { <UavLink title="View in Portal" onClick={addPortalClick}>
            <ImageIcon />
          </UavLink> }
          <UavLink title="Run Extraction" id="gobutton" style={{}} onClick={analyseClick}>
            <SuitabilityAnalysisOutputIcon />
          </UavLink>
          <UavLink title="Metadata" onClick={detailsClick}>
            <InformationIcon />
          </UavLink>
        </UavLinksBar>
      </CardFooter>
    </CardClass>
  );
}

export default ImageCard;
