import React, { useState, useContext, useEffect } from "react";
import { ImageContext } from "./ImageContext";
import { MapContext } from "./MapContext";
import { MapAOIContext } from "./MapContext";
import {AnalysisModeContext} from './ImageContext'
import {ProcessingResultsContext} from './ImageContext'


import Axios from "axios";
import Select from "calcite-react/Select";
import Button from "calcite-react/Button";
import FileUploader from "calcite-react/FileUploader";
import styled from "styled-components";
import Loader from "calcite-react/Loader";


const apiKey =
   "a565e1c9ac52f1eec14f74f9fb376222b3760ea4450e5a27bba4dbc95c3ef9a8"; //GICHD
  //"a78f4c75e6ebaf77809637e1764c76df10933a9126bc9b5417419a3fc40a1343"; //XAVIER
const apiServer = "https://app.picterra.ch/public/api/v1";

const Buttonx = styled(Button)`
  display:inline;
`;
const Selectx = styled(Select)`
  display:inline;
`;

const FileUploaderx = styled(FileUploader)`
  display:inline;
`;

const DivBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const Loaderx = styled(Loader)`
  display:inline;
  justify-self:end
`;



   // "7a76dde5-55a0-4c31-bdb2-5edced4381b3"



function Picterra() {
  const [uploadedDetection, setUploadedDetection] = useState();
  const [imageList, setImageList] = useState([]);
  const [detectorList, setDetectorList] = useState([]);
  const [geojsonFile, setGeojsonFile] = useState(null);
  const [selectedDetector, setSelectedDetector] = useState(); //    "d2545926-2e28-4791-be96-7330f51beeba"

  const [selectedRaster, setSelectedRaster] = useState(
    "fbc9cc92-4cfe-4b36-ae0c-46f1ff23c049"
  );
  const [processingStatus, setProcessingStatus] = useState(false);

  const image = useContext(ImageContext);
  const [getMapData, setMapData] = useContext(MapContext);
  const [getMapAOI, setMapAOI] = useContext(MapAOIContext)
      const [AnalysisMode, setAnalysisMode] = useContext(AnalysisModeContext)
      const [processingResults, setProcessingResults] = useContext(ProcessingResultsContext);
      const config = { "X-Api-Key": apiKey };

  if(!processingResults){
 //   setProcessingResults("https://storage.googleapis.com/picplat-prod/polygons/d1b3c38a-8326-4c7b-a23d-cb79682c9eb2/polygons.geojson?Expires=1597752753&GoogleAccessId=573911283456-compute%40developer.gserviceaccount.com&Signature=z1xOw%2FkGMYXa6wOAkIXXgf8b%2B0ep72y6LQJwkLkLsH3b3IeByHMjiZyN6S6BDsxGH0obDJOCTRltlSM4cp1ygzVu7Mb140Jn6iu0QYRZQY93hio%2FyNfQdIfGqnhztNkKF%2BZBXI7yf6qM9eo0YuEYj%2FnIO8ZLGnioTPMHgT9u5wA7sLFepxciG4crYmGcYOugMuP2wWs%2B0S3QSlglaon5PvEAF6OLlSwUNeElJ1heTyglk2diBmPUSBOH0LOtHn2EcrzNZvvmO4pHs0Y785tjZX7npYx6JBkzrGgMQpr9BgN9xceJrhDjR1CkwNyErL785eyEdPwchM65VzWECP1CyA%3D%3D")
  }

useEffect(()=>{
  if(true){
    getDetectors()
    console.log('s')
  }
}, [AnalysisModeContext]);

useEffect(()=>{
  if(true){

    console.log("useing",selectedDetector)
  }
}, [selectedDetector]);


  useEffect(() => { 
    console.log("shiz changing yoo", getMapAOI)
  

    if (getMapAOI){
   //let data = new Blob([getMapAOI], {type: 'text/json'});
   let data = new File([JSON.stringify(getMapAOI)], "aoi.geojson", {
    type: "application/json",
  });
    //  setGeojsonFile(data)
  //    console.log(geojsonFile.size)
   //   console.log(geojsonFile)
      console.log(getMapAOI)
      let json  = `{
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "id": 1,
            "geometry": ${JSON.stringify(getMapAOI)},
            "properties": {
              "OBJECTID": 1,
              "label": null,
              "SHAPE_Length": "",
              "SHAPE_Area": ""
            }
          }
        ]
      }`
      
      const blob = new Blob([json],{type:'application/json'});
    //  const href = URL.createObjectURL(blob);
    //  const link = document.createElement('a');
     // link.href = href;
     // link.download = "aoi.geojson";
    //  document.body.appendChild(link);
    //  link.click();
     // document.body.removeChild(link);
      setGeojsonFile(blob)


    }
    //makeFile = function (getMapAOI) {
   
    //}

    //const fs = require('fs'); 
  /*
let data = getMapAOI; 
  
fs.writeFile("aoi.geojson", data, (err) => { 
  if (err) 
    console.log(err); 
  else { 
    console.log("File written successfully\n"); 
    console.log("The written has the following contents:"); 
    console.log(fs.readFileSync("aoi.geojson", "utf8")); 
  } 
}); 
*/

   }, [getMapAOI]);
  



  function getRastrs() {
    Axios.get(`${apiServer}/rasters/`, { headers: config }).then((results) => {
      setImageList(results.data);
      console.log(results.data);
    });
  }
  function getDetectors() {
    Axios.get(`${apiServer}/detectors/`, { headers: config }).then(
      (results) => {
        setDetectorList(results.data);
        console.log(results.data);
      }
    );
  }

  function sendDetectionArea() {
    setProcessingStatus(true);

    let rasterId = selectedRaster;
    Axios.post(
      `${apiServer}/rasters/${rasterId}/detection_areas/upload/file/`,
      {},
      { headers: config }
    ).then((results) => {
      //setImageList(results.data);
      console.log(results.data.upload_url);
      console.log(results.data.upload_id);

      //     axios.put(url[, data[, config]])
      /* Axios.put(
          results.data.upload_url,{geojsonFile, ${results.data.upload_id}},{{ "X-Api-Key": apiKey },{"size":geojsonFile.size}}
        ).then((results) => {
          //setImageList(results.data);
          console.log(results.data.status);
          console.log(results.data);
          console.log(results);
        });

*/
      let upload_url = results.data.upload_url;
      let upload_id = results.data.upload_id;
      let file = geojsonFile;
      let size = geojsonFile.size;

      let options = {
        method: "PUT",
        body: file,
        headers: new Headers({ "content-length": size }),
      };
      fetch(`https://cors-anywhere.herokuapp.com/${upload_url}`, options).then(
        (results) => {
          if (results.status != 201 && results.status != 200) {
            console.log(results);
            throw new Error("Error uploading raster");
          }
          console.log(results);
          Axios.post(
            `${apiServer}/rasters/${rasterId}/detection_areas/upload/${upload_id}/commit/`,
            {},
            { headers: config }
          ).then((results) => {
            if(results.status === 201){
              setUploadedDetection(true)
              setProcessingStatus(false);

            }
            console.log(results);
          });
        }
      ); // Upload detection area
    });

    // https://app.picterra.ch/public/api/v1/rasters/{raster_id}/detection_areas/upload/file/ //post to api    // await on   `${apiServer}/rasters/${rasterId}/detection_areas/upload/file/`,    // if success 201

    // get url upload uploadUrl = data.upload_url // e.g. "https://storage.picterra.ch?id=AEnB2UmSEvVl"
    // get upload id uploadId = data.upload_id // e.g. "123e4567-e89b-12d3-a456-426655440000"
    // geojson file of area

    //file = geojsonFile[]

    // file = document.querySelector('input[type="file"]#file').files[0]
    //  size = file.size

    /*


  PUT on the upload url
  options = {method: 'PUT', body: file, headers: new Headers({ 'content-length': size })
}

UPLOAD, await post response if not 201 / 200 error else coommit upload
  `${apiServer}/rasters/${rasterId}/detection_areas/upload/${uploadId}/commit/`,

await get      `${apiServer}/rasters/${rasterId}/detection_areas/upload/${uploadId}/`,

  data = await response.json()
    isReady = (data.status == "ready")
 */
  }
  async function runDetection() {
    //https://app.picterra.ch/public/api/v1/detectors/{detector_id}/run/
    /*
    Axios.get(`${apiServer}/rasters/`, { headers: config }).then((results) => {
      setImageList(results.data);
      console.log(results.data);
    }); */
    setProcessingStatus(true);
    var fetch = window.fetch; // https://caniuse.com/#feat=fetch, https://github.com/github/fetch
    var Headers = window.Headers; // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API

    const sleep = (s) =>
      new Promise((resolve) => setTimeout(resolve, s * 1000));

    const detectorId = selectedDetector; // A trained custom detector
    const rasterId = selectedRaster; // A raster uploaded and committed
    let options, response, data, isReady;
    options = {
      method: "POST",
      headers: new Headers({
        "content-type": "application/json",
        "X-Api-Key": apiKey,
      }),
      body: JSON.stringify({
        raster_id: rasterId,
      }),
    };
    response = await fetch(
      // Start detection
      `${apiServer}/detectors/${detectorId}/run/`,
      options
    );
    if (response.status != 201) throw new Error("Error starting detection");
    /* Here the method ends, following full detection iter */
    data = await response.json();
    const pollInterval = data.poll_interval;
    const resultId = data.result_id; // e.g. "123e4567-e89b-12d3-a456-426655440000"
    const timeout = Date.now() + 10 * 60 * 1000; // 10 minutes timeout for polling
    isReady = false;
    do {
      // Check result status
      await sleep(pollInterval);
      response = await fetch(`${apiServer}/results/${resultId}/`, {
        headers: new Headers({ "X-Api-Key": apiKey }),
      });
      if (response.status != 200) throw new Error("Error checking result");
      data = await response.json();
      isReady = data.ready;
      isReady
        ? setProcessingResults(data.result_url)
        : console.log("processing");
      isReady ? console.log(data.result_url) : console.log("processing");
    } while (!isReady && Date.now() < timeout); // Poll until complete
    setProcessingStatus(false);

    return isReady; // If True you can download detection results at data.result_url)
  }

  function getDetectionResults() {

    Axios.get(`https://cors-anywhere.herokuapp.com/${processingResults}`, { headers: config }).then((results) => {
      console.log("processingResults",results.data);
      setMapData(results.data)

    });
  } 
  function runCleanup() {
    
    //setSelectedDetector();
  
  // 
   // setProcessingStatus(false);



   // setGeojsonFile(); not resetting this value to keep existing polygon
  



   // needs to zoom to polygon again
   // show send detection area again
   //setUploadedDetection(false)

   // keep model list
  setUploadedDetection(false) //this controls model dropdown
  setGeojsonFile()


   // hide view results
   // hide download results
   // hide reset
   setProcessingResults(false)



  } 

  function uploadFile(e) {
    setGeojsonFile(e.target.files[0]);
    console.log("upload file");
    console.log(geojsonFile);
    console.log(geojsonFile.size);
  }

  return (
    <div>
    <DivBox style={{ display: AnalysisMode ? "inline" : "none" },{align:"center"}}>
      <FileUploaderx  style={{ display: false ? "inline" : "none" }} type="file" onChange={uploadFile} />
      <Buttonx style={{ display: geojsonFile && !uploadedDetection? "inline" : "none"}} onClick={sendDetectionArea}>Send detection area</Buttonx>
      <Buttonx style={{ display: false ? "inline" : "none" }}  onClick={getRastrs}>get rasters</Buttonx>

      <select style={{ display: false ? "inline" : "none" }}  filterable={true} minimal={true} horizontal={false} fullWidth={false}>
        {imageList.map((image) => (
          <option value={image.id} key={image.id}>
            {image.name}
          </option>
        ))}
      </select>
      <Buttonx style={{ display: false ? "inline" : "none" }} onClick={getDetectors}>get detectors</Buttonx>

      <select   style={{ display: uploadedDetection ? "inline" : "none" }} filterable={true} fullWidth={false} onChange={(e)=>setSelectedDetector(e.target.value)}>
      <option selected="selected" disabled="disabled">Select a Model</option>

        {detectorList.map((det) => (
          <option value={det.id} key={det.id}>
            {det.name}
          </option>
        ))}
      </select>
      <Buttonx style={{ display: uploadedDetection && selectedDetector ? "inline" : "none"}} onClick={runDetection}>Run detection</Buttonx>

      <Buttonx
        style={{ display: processingResults ? "inline" : "none" }}
        onClick={getDetectionResults}
      >
        View Results
      </Buttonx>
      <Buttonx
        style={{ display: processingResults ? "inline" : "none" }} href={processingResults}
      >
        Download Results
      </Buttonx>

      <Buttonx
        style={{ display: processingResults ? "inline" : "none" }} onClick={runCleanup}
      >
        Reset
      </Buttonx>

    </DivBox>
    <Loader sizeRatio={0.5} style={{ display: processingStatus ? "block" : "none" }} />

    </div>
  );
}

export default Picterra;
